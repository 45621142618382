import { View, StyleSheet, Text, Pressable, Linking } from "react-native"
import tema from "../../assets/theme"
import { useNavigation } from "@react-navigation/native";
import Svg, { Path } from "react-native-svg";

const Footer = () => {

    const navigation = useNavigation<any>();
    const styles = StyleSheet.create({
        container: {
            width: "100%",
            backgroundColor: tema.colors.siteCor,
            height: 'auto',
            flexDirection:'row'
        },
        pressable:{
            
            alignSelf: 'flex-start',
        }
        ,
        content:{
            flex:1,
            paddingHorizontal: '5%',
            paddingVertical: '1%'
        },

        titulo: {
            color: tema.colors.corSecundaria,
            fontWeight: 'bold',
            marginBottom: 20
        },

        items: {
            color: tema.colors.corSecundaria,
            marginBottom: 10
        }
    })

    return (
        <View style={styles.container}>
            <View style={styles.content}>
                <Pressable style={styles.pressable}>
                    <Text  style={styles.items} onPress={() => {
                                        navigation.navigate('TelaInicial')}
                                    }>
                        Destaques
                    </Text>
                </Pressable>
                <Pressable style={styles.pressable} onPress={() => {
                                        navigation.navigate('SulAm')}
                                    }>
                    <Text  style={styles.items}>
                        Sul-americano
                    </Text>
                </Pressable>
                <Pressable style={styles.pressable}  onPress={() => {
                                        navigation.navigate('Europeu')}
                                    }>
                    <Text  style={styles.items}>
                        Europeu
                    </Text>
                </Pressable>
                
            </View>
            <View style={styles.content}>
{/* 
                <Text style={{marginBottom: 20}}></Text> */}
                <Pressable style={styles.pressable}  onPress={() => {
                                        navigation.navigate('Transferencias')}
                                    }>
                    <Text  style={styles.items}>
                        Transferências
                    </Text>
                </Pressable>
                <Pressable style={styles.pressable} onPress={() => {
                                        navigation.navigate('DiaDia')}
                                    }>
                    <Text  style={styles.items}>
                        Dia a Dia
                    </Text>
                </Pressable>
                <Pressable style={styles.pressable}  onPress={() => {
                                        navigation.navigate('Anuncios')}
                                    }>
                    <Text  style={styles.items}>
                        Anuncios
                    </Text>
                </Pressable>
            </View>

            <View style={styles.content}>

                <Text style={styles.titulo}>Contate nos</Text>
                <Pressable style={styles.pressable} onPress={()=>Linking.openURL('https://instagram.com/acamisa9_?igshid=ZDdkNTZiNTM=')}>

                    <View style={{flex: 1, flexDirection: 'row'}}>
                        <Svg height="24" width="24">
                            <Path d="M8,3c-2.757,0 -5,2.243 -5,5v8c0,2.757 2.243,5 5,5h8c2.757,0 5,-2.243 5,-5v-8c0,-2.757 -2.243,-5 -5,-5zM8,5h8c1.654,0 3,1.346 3,3v8c0,1.654 -1.346,3 -3,3h-8c-1.654,0 -3,-1.346 -3,-3v-8c0,-1.654 1.346,-3 3,-3zM17,6c-0.55228,0 -1,0.44772 -1,1c0,0.55228 0.44772,1 1,1c0.55228,0 1,-0.44772 1,-1c0,-0.55228 -0.44772,-1 -1,-1zM12,7c-2.757,0 -5,2.243 -5,5c0,2.757 2.243,5 5,5c2.757,0 5,-2.243 5,-5c0,-2.757 -2.243,-5 -5,-5zM12,9c1.654,0 3,1.346 3,3c0,1.654 -1.346,3 -3,3c-1.654,0 -3,-1.346 -3,-3c0,-1.654 1.346,-3 3,-3z" fill="white" />
                        </Svg>
                        <Text  style={styles.items}>
                              Instagram
                        </Text>
                    </View>
                </Pressable>

                <Pressable style={styles.pressable} onPress={()=>Linking.openURL('mailto:contato@acamisa9.com.br')}>
                    <View style={{flex: 1, flexDirection: 'row'}}>
                        <Svg height="24" width="24">
                            <Path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z" fill="white" />
                        </Svg>
                        <Text  style={styles.items}>
                              Email
                        </Text>
                    </View>
                </Pressable>
            </View>
        </View>
    )

}

export default Footer