
import { StatusBar } from 'expo-status-bar';
import { useEffect, useState } from 'react';
import { Button, ScrollView, StyleSheet, Text, View, Image } from 'react-native';
import WpApi from '../../../services/wpApi';
import HeaderFC from '../../Header/header';
import PostComponent from '../../Post/Post';
import PrincipalComponent from '../../PrincipalDaPAgina/Principal';
import RenderPosts from '../../RenderPosts/RenderPosts';
import LoadingComp from '../../Loading/loading';
import Footer from '../../Footer/footer';
import { GamesWidgets } from '../../SoccerWidget/Soccerwidgets';

 const Europeu = ({navigation}:any) => {

  const [posts, setPosts] = useState<any>()
  const [loading, setLoading] = useState(true)
  const postsPerRequest= 5
  let api = new WpApi(setPosts, postsPerRequest);

  useEffect(() => {
    api.getSpecificPosts('7').then(()=>{
      
      setLoading(false)})
  }, [])

  

  if(loading && !posts){

    return(
      <LoadingComp  pagenum={4}></LoadingComp>
    )
  }
  return (
    <View style={styles.container}>
      <HeaderFC pageNum={4}/>
      
      <View style={styles.scrollView}>
        
        <PrincipalComponent props={posts[0]}></PrincipalComponent> 
        <View style={{height: 350, width: '80%'}}>
          <GamesWidgets league='2' season='2022'></GamesWidgets>
        </View>
        <View style={styles.postContainer}>

          {
            
          <RenderPosts setPosts={setPosts} posts={posts} api={api} postsPerRequest={postsPerRequest}></RenderPosts>
          }
        </View>
        
        <Footer></Footer>
        
        {/* <Button
            onPress={() => {
                navigation.navigate('Noticia', {excerpt: posts[0].excerpt, title: posts[0].title, content: posts[0].content, image: posts[0].image})
            }}
            title="push me"
          /> */}
{/* 
        <Button
          onPress={() => {
            api.pullData()
          }}
          title="Dados"
        />
        <Button
          onPress={() => {
            api.searchTags()
          }}
          title="Dados"
        /> */}

        {/* <Button
          onPress={() => {
            console.log(posts)
          }}
          title="Post"
        /> */}
      </View>

      
        
      <StatusBar style="auto" />
    </View>
  );
}




export default Europeu;
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    scrollView:{
      backgroundColor: "pink",
      height: "5%",
      width: "100%",
      alignItems:'center',
      position:'absolute',
      top: 0,
    },
    postContainer:{
      width:'100%',
      alignItems:'center',
      marginTop: "5%"
    }
  });
