
import { useEffect, useState } from 'react';
import { Button, ScrollView, StyleSheet, Text, View, Image, useWindowDimensions } from 'react-native';
import HeaderFC from '../Header/header';
import tema from '../../assets/theme';

const LoadingComp = ({pagenum}: {pagenum:number}) =>{
  
  const {height, width} = useWindowDimensions();
  const styles = StyleSheet.create({
    imagemTelaGrande:{
      padding:"20%",
      marginTop: '10%',
    },
    imagemTelaMedia: {
      padding:"30%",
      marginTop: '20%',
    },
    imagemTelaPequena: {
      padding:"50%",
      marginTop: '50%',
    }
  })
  return(
  <View style={{flex: 1, flexDirection: 'column', backgroundColor: tema.colors.siteCor}}>
          
      <HeaderFC pageNum={pagenum}/>
      <Image resizeMode='contain' style={width > 1030 ? styles.imagemTelaGrande: width > 500 ? styles.imagemTelaMedia : styles.imagemTelaPequena} source={require('../../assets/logos/escbranco.png')} />
  </View>
  )
}

export default LoadingComp;