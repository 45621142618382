const apiKey = process.env.API_KEY;
export const StandingsWidgets = ({league, season}: {league: string, season: string}) =>{
    return(
        <iframe
                srcDoc={`
                  <html>
                    <head>
                      <script src="https://widgets.api-sports.io/2.0.3/widgets.js" type="module"></script>
                    </head>
                    <body>
                    
                    <div id="wg-api-football-standings"
                      data-host="v3.football.api-sports.io"
                      data-key="${apiKey}"
                      data-league="${league}"
                      data-season="${season}"
                      data-theme="dark"
                      data-lang="pt_br"
                      data-show-errors="false"
                      data-show-logos="true"
                      class="wg_loader">
                    </div>
                    </body>
                  </html>
                  

                `}
                style={{ width: '100%', height: '100%', marginTop: 10, marginBottom: 10 }}
            />
    )
}

export const GamesWidgets = ({league, season}: {league: string, season: string}) =>{
    return(
          <iframe
              srcDoc={`
                <html>
                  <head>
                    <script src="https://widgets.api-sports.io/2.0.3/widgets.js" type="module"></script>
                  </head>
                  <body>
                  <div id="wg-api-football-games"
                    data-host="v3.football.api-sports.io"
                    data-key="${apiKey}"
                    data-date=""
                    data-league="${league}"
                    data-season="${season}"
                    data-theme="dark"
                    data-refresh="1000"
                    data-lang="pt_br"
                    data-show-toolbar="true"
                    data-show-errors="false"
                    data-show-logos="true"
                    data-modal-game="true"
                    data-modal-standings="true"
                    data-modal-show-logos="true">
                  </div>
                  </body>
                </html>
                

              `}
              style={{ width: '100%', height: '100%', marginTop: 10, marginBottom: 10 }}
            />
    )
}

  