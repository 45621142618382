
import { StatusBar } from 'expo-status-bar';
import { useEffect, useState } from 'react';
import { Button, ScrollView, StyleSheet, Text, View, Image, useWindowDimensions } from 'react-native';
import WpApi from '../../../services/wpApi';
import HeaderFC from '../../Header/header';
import PostComponent from '../../Post/Post';
import PrincipalComponent from '../../PrincipalDaPAgina/Principal';
import RenderPosts from '../../RenderPosts/RenderPosts';
import LoadingComp from '../../Loading/loading';
import Footer from '../../Footer/footer';
import {GamesWidgets, StandingsWidgets} from '../../SoccerWidget/Soccerwidgets';

 const TelaInicial = ({navigation}:any) => {

  const {height, width} = useWindowDimensions();
  const [posts, setPosts] = useState<any>()
  const [loading, setLoading] = useState(true)
  const postsPerRequest = 5
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      justifyContent: 'center',
    },
    scrollView:{
      backgroundColor: "pink",
      height: "5%",
      width: "100%",
      alignItems:'center',
      position:'absolute',
      top: 0,
    },
    postContainer:{
      width:'100%',
      marginTop: "5%",
      flexDirection: width < 900 ? undefined :'row-reverse'
    }
  });
  const widget = {
    html: `
    <div id="wg-api-football-game"
      data-host="api-football-v1.p.rapidapi.com"
      data-key="5132b6c5a0msh72b65ba93e97a3cp16eb8ejsn3a8ca5df54a8"
      data-id="718243"
      data-theme="dark"
      data-refresh="60"
      data-show-errors="true"
      data-show-logos="true">
    </div>
    <script
      type="module"
      src="https://widgets.api-sports.io/2.0.3/widgets.js">
    </script>`
    
  }

{/* <iframe
            srcDoc={`
              <html>
                <head>
                  <script src="https://widgets.api-sports.io/2.0.3/widgets.js" type="module"></script>
                </head>
                <body>
                <div id="wg-api-football-games"
                  data-host="v3.football.api-sports.io"
                  data-key="276a9af92de96ab9dbdd4a0d1cb85635"
                  data-date=""
                  data-league="2"
                  data-season="2023"
                  data-theme="dark"
                  data-refresh="1000"
                  data-lang="pt_br"
                  data-show-toolbar="true"
                  data-show-errors="false"
                  data-show-logos="true"
                  data-modal-game="true"
                  data-modal-standings="true"
                  data-modal-show-logos="true">
                </div>
                </body>
              </html>
              

            `}
            style={{ width: '100%', height: '400px' }}
          /> */}



  let api = new WpApi(setPosts, postsPerRequest);

  useEffect(() => {
    api.getLatestPosts().then(()=>{
      
      setLoading(false)})
  }, [])


  if(loading && !posts){

    return(
      <LoadingComp pagenum={1}></LoadingComp>
    )
  }
  return (
    <View style={styles.container}>
      <HeaderFC pageNum={1}/>
      
      
      <View style={styles.scrollView}>
        
        <PrincipalComponent props={posts[0]}></PrincipalComponent>
        
        
        
        <View style={styles.postContainer}>
          <View style={{height: 550,}}>
            <StandingsWidgets league='71' season='2023'></StandingsWidgets>
          </View>
            <RenderPosts setPosts={setPosts} posts={posts} api={api} postsPerRequest={postsPerRequest}></RenderPosts>
            
          </View>
          
        
        <Footer></Footer>
        
        {/* <Button
            onPress={() => {
                navigation.navigate('Noticia', {excerpt: posts[0].excerpt, title: posts[0].title, content: posts[0].content, image: posts[0].image})
            }}
            title="push me"
          /> */}

        {/* <Button
          onPress={() => {
            api.pullData()
          }}
          title="Dados"
        /> */}
        {/* <Button
          onPress={() => {
            api.searchTags()
          }}
          title="Dados"
        /> */}

        {/* <Button
          onPress={() => {
            console.log(posts)
          }}
          title="Post"
        />

        <Button
          onPress={() => {
            setAdditionalPosts(true)
             
          }}
          title="Novos"
        /> */}


      </View>

      
        
      <StatusBar style="auto" />
    </View>
  );
}



export default TelaInicial;

