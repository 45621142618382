

const tema ={
    colors: {
       siteCor:  'black',
       corSecundaria: 'white',
       corOriginal: 'rgb(233, 151, 0)'
    },
    fonts: {
        titulo: 'impact',
        texto: 'nanum-gothic',
        categorias: '',
        ranking: ''
    }
}

export default tema;