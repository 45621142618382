import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View } from 'react-native';
import HeaderFC from './components/Header/header';
import DiaDia from './components/telas/DiaDia/DiaDia';
import Europeu from './components/telas/Europeu/Europeu';
import SulAmericano from './components/telas/SulAmericano/SulAmericano';
import TelaInicial from './components/telas/TelaInicial/inicial';
import Transferencias from './components/telas/Transferencias/Transferencias';
import Noticia from './components/telas/Noticia/noticia';
import Anuncios from './components/telas/Anuncios/Anuncios';

const Stack = createNativeStackNavigator();

export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen
          name="TelaInicial"
          component={TelaInicial}
          options={{
            title: 'Os mais vistos!',
            headerShown: false,
          }}
        />
        <Stack.Screen 
          name="SulAm"
          component={SulAmericano}
          options={{
            title: 'Sul Americano',
            headerShown: false
          }}  />
        <Stack.Screen 
          name="Europeu"
          component={Europeu}
          options={{
            title: 'Futebol Europeu',
            headerShown: false
          }}  />
        <Stack.Screen 
          name="Transferencias"
          component={Transferencias}
          options={{
            title: 'Transferências',
            headerShown: false
          }}  />
        <Stack.Screen 
          name="Anuncios"
          component={Anuncios}
          options={{
            title: 'Anuncios',
            headerShown: false
          }}  />
        <Stack.Screen 
          name="DiaDia"
          component={DiaDia}
          options={{
            title: 'Dia a Dia',
            headerShown: false
          }}  />
        <Stack.Screen 
          name="Noticia"
          component={Noticia}
          options={{
            title: 'A camisa 9',
            headerShown: false
          }}  />
      </Stack.Navigator>
    </NavigationContainer>
    
  );
}

