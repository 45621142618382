import { useNavigation } from "@react-navigation/native";
import { Button, View, StyleSheet, Pressable, Text, SafeAreaView, Platform, Image, TouchableWithoutFeedback, useWindowDimensions } from "react-native"
import tema from "../../assets/theme";
import DropdownMenu from "./DropdownMenu";

type HeaderProps ={
    pageNum: number;
}

const HeaderFC = (props: HeaderProps) =>{
    const navigation = useNavigation<any>();
        
    const {height, width} = useWindowDimensions();
    const styles = StyleSheet.create({
        header:{
            width: '100%',
            top: 0,
            position:'absolute',
            backgroundColor: tema.colors.siteCor,
            flexDirection: "row",
            zIndex: 4

        },
        button: {
            paddingHorizontal: 25,
            alignItems: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
            paddingVertical: 20,
            ...(Platform.OS === 'android' && { marginTop: 56 }),
            elevation: 3,
            color: 'black',
        },
        text: {
            fontSize: width > 1270 ?  16: 10,
            lineHeight: 21,
            fontWeight: 'bold',
            letterSpacing: 0.25,
            color: 'white',
            textAlign: "center"
        },
        selecionado: {
            backgroundColor: 'white',
            width: width < 1000 ? '100%': '',
            color:  tema.colors.siteCor
        }
    });

    const BotoesHeader = (isRow: boolean = true) =>{
        return (
            <View style={{flexDirection: isRow? 'row': 'column'}}>  
                <Pressable style={[styles.button, (props.pageNum == 1) ?  styles.selecionado : null]}
                                    onPress={() => {
                                        navigation.navigate('TelaInicial')}
                                    }>
                    <Text style={[styles.text, (props.pageNum == 1) ?  styles.selecionado : null]}>DESTAQUES</Text>
                </Pressable>


                <Pressable style={[styles.button, (props.pageNum == 3) ?  styles.selecionado : null]}
                                    onPress={() => {
                                        navigation.navigate('SulAm')}
                                    }>
                    <Text style={[styles.text, (props.pageNum == 3) ?  styles.selecionado : null]}>FUTEBOL SUL-AMERICANO </Text>
                </Pressable>

                <Pressable style={[styles.button, (props.pageNum == 4) ?  styles.selecionado : null]}
                                    onPress={() => {
                                        navigation.navigate('Europeu')}
                                    }>
                    <Text style={[styles.text, (props.pageNum == 4) ?  styles.selecionado : null]}>FUTEBOL EUROPEU </Text>
                </Pressable>

                <Pressable style={[styles.button, (props.pageNum == 5) ?  styles.selecionado : null]}
                                    onPress={() => {
                                        navigation.navigate('Transferencias')}
                                    }>
                    <Text style={[styles.text, (props.pageNum == 5) ?  styles.selecionado : null]}>TRANSFERÊNCIAS </Text>
                </Pressable>

                <Pressable style={[styles.button, (props.pageNum == 6) ?  styles.selecionado : null]}
                                    onPress={() => {
                                        navigation.navigate('DiaDia')}
                                    }>
                    <Text style={[styles.text, (props.pageNum == 6) ?  styles.selecionado : null]}>DIA-A-DIA</Text>
                </Pressable>

                <Pressable style={[styles.button, (props.pageNum == 2) ?  styles.selecionado : null]}
                                    onPress={() => {
                                        navigation.navigate('Anuncios')}
                                    }>
                    <Text style={[styles.text, (props.pageNum == 2) ?  styles.selecionado : null]}>ANUNCIOS</Text>
                </Pressable>
            </View>
        )
    }

    return(
        <SafeAreaView  style= {styles.header}>
            <TouchableWithoutFeedback onPress={() => {
                                    navigation.navigate('TelaInicial')}
                                }>
                    
                <Image style={{ paddingHorizontal: 100, marginHorizontal: 5,}} source={require('../../assets/logos/Camisa-Nove-Branco.png')} />
            </TouchableWithoutFeedback>

            {
                width > 1000 
                    ? (<View >{BotoesHeader()}</View>)
                    : (<DropdownMenu options={BotoesHeader}></DropdownMenu>)
            }
            
        </SafeAreaView >
    )
    
}


export default HeaderFC;