import React, { useEffect, useRef, useState } from "react";
import WpApi from "../../../services/wpApi";
import { View, Text, Dimensions, ImageBackground, Image, useWindowDimensions, ScrollView, ScrollViewProps, Button } from "react-native";
import { StyleSheet } from "react-native";
import HeaderFC from "../../Header/header";
import tema from "../../../assets/theme";
import HTML from 'react-native-render-html';
import RenderPosts from "../../RenderPosts/RenderPosts";
import LoadingComp from "../../Loading/loading";
import Footer from "../../Footer/footer";

type ScrollViewWithRef = ScrollViewProps & {
  scrollToTop: () => void;
};

const Noticia = ({route}:any) => {

    const [posts, setPosts] = useState<any>()
    const [loading, setLoading] = useState(true)
    const postsPerRequest= 5
    let api = new WpApi(setPosts, postsPerRequest);
    const {height, width} = useWindowDimensions();

    const scrollViewRef = useRef<ScrollView>(null);

    const handleScrollToTop = () => {
      window.scrollTo({top:0, behavior: 'smooth'})
    };

  const styles = StyleSheet.create({

    textSemelhantes :{

      color: "white",
      fontFamily: tema.fonts.titulo,
      textAlign: 'center',
      fontSize: 35
      
    },
    semelhantes: {
      height: 40,
      backgroundColor: tema.colors.siteCor,
      width: "80%",
      alignSelf: "center"

    },
    postContainer:{
      width:'100%',
      alignItems:'center',
      marginTop: "5%"
    },
    topText: {
        width: "100%",
        paddingTop: "15%",
        backgroundColor: tema.colors.siteCor,
        paddingVertical: 20
    },
    container: {
      position: 'absolute', 
      flex: 1,
      flexDirection: 'column',
      width: "100%"
    },

    titleText: {
      top: '5%',
      marginLeft: "4%",
      fontFamily: tema.fonts.titulo,
      color: "white",
      fontSize: (width > 800 ?  width/12: width/10),
      paddingTop: 10
    },

    subtitleText: {
      position: "relative",
      color: "white",
      textAlign: 'auto',
      paddingHorizontal: 20,
      alignSelf: 'center',
      fontFamily: tema.fonts.texto,
      fontSize: 20
    },

    imageContainer: {
      justifyContent: 'center',
      width: '100%',
      backgroundColor: "white"
    },

    image:{
    
      width: width > 1000 ? "60%" : '90%',
      aspectRatio: 2,
      alignSelf: 'center',
      marginTop: '5%',
      borderStyle: 'solid',
      borderColor: tema.colors.siteCor,
      borderWidth: 10,
    },

    contentContainer:{
      color: "white",
      marginBottom: 20
    },

    contentText: {
      width: '90%',
      alignSelf: 'center',
      marginTop: "5%",
      paddingHorizontal: "2%",
      borderLeftColor: tema.colors.siteCor,
      borderRightColor: tema.colors.siteCor,
      borderLeftWidth: 15,
      borderRightWidth: 15
    }
  })
    
    useEffect(() => {
      if( route.params.sem){
        api.getSpecificPosts(route.params.sem).then(()=>{
        setLoading(false)
      })
      }
      else{
        api.getLatestPosts().then(()=>{
          setLoading(false)
        })
      }
      
    }, [route])
    
  
    if(loading && !posts){
  
      return(
        <LoadingComp pagenum={0}></LoadingComp>
      )
    }
    
    return (
      <ScrollView ref={scrollViewRef} style={styles.container}>
        <HeaderFC pageNum={0}></HeaderFC>
        <View style={styles.topText}>
            <Text style={styles.titleText} >{route.params.title.rendered}</Text>
            <HTML contentWidth={width} tagsStyles={{body:{color:tema.colors.corSecundaria, marginHorizontal:'5%', fontSize: "20px"}}} source={{html:route.params.excerpt.rendered}}></HTML>
        </View>

        <View style={styles.imageContainer}>
          <Image  style={styles.image} source={route.params.image}/>
        </View> 
        
        <View style={styles.contentContainer}>
          <Text style={styles.contentText}>
            <HTML contentWidth={width} tagsStyles={{body: {fontSize: width > 1200 ? '30px': '20'}}} source={{html:route.params.content.rendered}}></HTML>
          </Text>
        </View>

        <View style={styles.semelhantes}>
          <Text style={styles.textSemelhantes}>Semelhantes</Text>
        </View>

        <View style={styles.postContainer}>
          {  
              <RenderPosts setPosts={setPosts} posts={posts} api={api} postsPerRequest={postsPerRequest} scrollTotop={handleScrollToTop}></RenderPosts>
          }
        </View>
        
        <Footer></Footer>
          
      </ScrollView>
    );
}

export default Noticia;
  