import { useEffect, useState } from "react"

import WpApi from '../../services/wpApi';
import PostComponent from "../Post/Post";
import { View, StyleSheet, Button, Pressable, Text, useWindowDimensions } from "react-native";
import { yellow } from "colorette";
import tema from "../../assets/theme";

const RenderPosts = ({setPosts, posts, api, postsPerRequest, scrollTotop}:{setPosts:any, posts: any, api: WpApi, postsPerRequest:number, scrollTotop?: any}) =>{
    
    const {height, width} = useWindowDimensions();

    const [additionalPosts, setAdditionalPosts] = useState(false)
    const [offset, setOffset] = useState(postsPerRequest)
    const [buttonDisabled, setButtonDisabled] = useState(false)

    useEffect(()=>{
    if(additionalPosts){
        setAdditionalPosts(false)
        api.aditionalPosts(offset).then(vetor=>{
        const newPosts = [...posts, ...vetor]
        setPosts(newPosts)
        setOffset(offset + postsPerRequest)
        if(vetor.length < postsPerRequest){
            setButtonDisabled(true)
        }
        })
    }
    },[posts,additionalPosts])

    const styles = StyleSheet.create({
        gray:{
            color: "gray"
        },
        button:{
            color: tema.colors.siteCor,
            borderRadius: 20,
            textAlign: 'right',
            fontFamily: tema.fonts.titulo,
            fontSize: 60,
        },
        
        postContainer:{
          width: width > 1440 ?'90%':(width > 900 ? '80%' : '90%'),
          alignItems:'flex-end',
        }
    });
    let renderArray: JSX.Element[] = []
    posts.map((element: any) => {
        renderArray.push(<PostComponent key={element.id} scrollToTop={scrollTotop} props={element}></PostComponent>)
    })
    return (
        <View  style={styles.postContainer}>

            {renderArray}

            <Pressable style={{alignSelf: 'flex-end', marginRight:"10%"}}  disabled={buttonDisabled}
                onPress={() => {
                    setAdditionalPosts(true)
                }}
            >
                <Text style={[styles.button , (buttonDisabled ? styles.gray : null)]}>+</Text>
            </Pressable>
        </View>

        )

}

export default RenderPosts

