import React, { useRef, useState } from 'react';
import { View, Text, TouchableOpacity,StyleSheet, useWindowDimensions, Platform, Pressable, TouchableWithoutFeedback } from 'react-native';
import tema from '../../assets/theme';
import { useNavigation } from '@react-navigation/native';
import Svg, { Path } from 'react-native-svg';

const DropdownMenu = ({options}: {options: (isRow: boolean) => JSX.Element}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);

  };

  return (
    <View style={{ flex:1}}>
        <Pressable onPress={()=>toggleDropdown()} style={[styles.button,]}>
                               
          <Svg height="32" width="32">
            <Path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2 s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2 S29.104,22,28,22z" fill="white" />
          </Svg>
        </Pressable>
      {isOpen && (
        <View
          style={{
            position: 'absolute',
            flex:1,
            top: 65,
            backgroundColor: tema.colors.siteCor,
            borderWidth: 1,
            borderTopColor: '#FFF',
            overflow: 'hidden',
            alignSelf: 'flex-end',
          }}
        >
          {/* Content of the dropdown menu */}
        
        {options(false)}
        
        </View>
      )}
    </View>
  );
};

export default DropdownMenu;

        
const styles = StyleSheet.create({
    button: {
        paddingHorizontal: 25,
        alignItems: 'center',
        alignSelf: 'flex-end',
        justifyContent: 'center',
        paddingVertical: 20,
        ...(Platform.OS === 'android' && { marginTop: 56 }),
        elevation: 3,
    },
    text: {
        fontSize: 16,
        lineHeight: 21,
        fontWeight: 'bold',
        letterSpacing: 0.25,
        color: tema.colors.corSecundaria,
    },
    selecionado: {
        backgroundColor: 'white',
        color:  tema.colors.siteCor
    }
});