
import { Image, Dimensions, ImageBackground, ImageSourcePropType, Pressable, StyleSheet, Text, TouchableOpacity, TouchableWithoutFeedback, View, useWindowDimensions } from 'react-native';
import tema from '../../assets/theme';
import { useNavigation } from '@react-navigation/native';
import HTML from 'react-native-render-html';

const WindowDimension = Dimensions.get('window')
// {id, title, content, date, slug, excerpt}

const PrincipalComponent = (props:any, id: string) =>{
    
    const {height, width} = useWindowDimensions();
    const navigation = useNavigation<any>();
    console.log(props.props.excerpt.rendered)
    const styles = StyleSheet.create({

        container:{
            width: '100%',
            alignItems: 'center',
            marginTop: "1%"
        },
    
        imageContainer: {
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: ( 0.6 * WindowDimension.height),
            position: 'relative',
    
        },
        
        overlay: {
            ...StyleSheet.absoluteFillObject,
            backgroundColor: 'rgba(233, 151, 0, 0.4)'
          }, 
        
        titleText: {
            fontFamily: tema.fonts.titulo,
            color: "white",
            fontSize: (width > 800 ? (width > 1300 ? width/16 : width/14): width/10),
            width: '100%',
            maxHeight: '100%',
            flex: 1,
            top: '10%',
            textAlign: 'center'
          },
          titleContainer:{
            position: 'absolute',
            height: '50%',
            top: '15%',
          }
          ,
          subtitulo: {
            textAlign: 'center',
            fontFamily: tema.fonts.texto,
            color: "white",
            fontSize: 20,
            textShadowOffset: { width: 2, height: 2 },
            textShadowColor: 'black',
            textShadowRadius: 10,
            shadowOpacity: 1,
            shadowOffset: { width: 2, height: 2 },
             
          }
      });

    
    return (
        <Pressable style={styles.container}  onPress={()=>postPage(props, navigation)}>
            <View style={styles.container}>
                <Image style={styles.imageContainer} source={{uri:props.props.image}} />
                <View style={{width: '100%'}}>
                    {
                        props.props.excerpt.rendered.length > 11?
                        (
                            <View style={{position: 'absolute', bottom: '2%', }}>
                                <HTML contentWidth={width} tagsStyles={{body:styles.subtitulo}} source={{html:props.props.excerpt.rendered}}></HTML>
                                
                            </View>
                        ):null
                    }
                    
                </View>
                <View style={styles.titleContainer}>
                    <Text style={styles.titleText}>
                            {(props.props.title.rendered).toUpperCase()}
                    </Text>
                </View>
                
                    
            </View>
        </Pressable>

    )


}

const postPage = (props: any, navigation: { navigate: (arg0: string, arg1: { excerpt: any; title: any; content: any; image: any; sem:any }) => void; })=>{
    navigation.navigate('Noticia', {excerpt: props.props.excerpt, title: props.props.title, content: props.props.content, image: props.props.image, sem: props.props.sem})
    
}

export default PrincipalComponent;
