
import { Dimensions, Image, ImageSourcePropType, Pressable, StyleSheet, Text, TouchableOpacity, TouchableWithoutFeedback, View, useWindowDimensions } from 'react-native';
import tema from '../../assets/theme';
import { useNavigation } from '@react-navigation/native';
import HTML from 'react-native-render-html';

// {id, title, content, date, slug, excerpt}

const PostComponent = ({props, scrollToTop} :{props: any, scrollToTop?:any} ) =>{
    const {height, width} = useWindowDimensions();
    const navigation = useNavigation<any>();
    const styles = StyleSheet.create({

        container:{
            width: '90%',
            alignItems: 'center',
        },
    
        image:{
            width: (width > 840 ? '60%': '70%'),
            height: '100%',
            alignSelf: 'flex-start'
        },
    
        imageContainer: {
            marginBottom:"2%",
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: (0.3 * height),
            position: 'relative'
        }
        ,
        triangleCorner1: {
            position: 'absolute',
            top:0,
            right:0,
            width: "55%",
            backgroundColor: 'transparent',
            borderStyle: 'solid',
            borderLeftWidth: 50,
            borderLeftColor: 'transparent',
            borderTopWidth: (0.301 * height),
            borderTopColor: tema.colors.siteCor
          },
        
          titleText: {
            position: 'absolute',
            right: "3%",
            top: '10%',
            fontFamily: tema.fonts.titulo,
            color: "white",
            flex: 1,
            textAlign: 'right',
            fontSize: width > 650 ? 20: 15,
            width: '40%'
          },
    
          subtitulo: {
            textAlign: 'center',
            fontFamily: tema.fonts.texto,
            color: "white",
            width: '100%',
            fontSize: 10,
            textTransform: 'capitalize',
            
          }
      });

    return (
        <Pressable style={styles.container}  onPress={()=>postPage(props, navigation, scrollToTop)}>
            <View style={styles.container}>
                <View style={styles.imageContainer} >
                    
                    <Image style={styles.image} source={{uri: props.image}}/>
                    <View style={styles.triangleCorner1}>
                    </View>
                    <Text style={styles.titleText}>
                        {(props.title.rendered).toUpperCase()}
                    </Text>
                    <View style={{position:'absolute', width: '40%',  right:0, bottom: '5%'}}>
                        {
                            width > 40 ? 
                                <HTML contentWidth={width} tagsStyles={{body:styles.subtitulo}} source={{html:props.excerpt.rendered.substring(0, 260)}}></HTML>
                            :null
                        }
                    </View>
                </View>
                    
            </View>
        </Pressable>

    )


}

const postPage = (props: any, navigation: { navigate: (arg0: string, arg1: { excerpt: any; title: any; content: any; image: any; sem:any }) => void; }, scrollToTop?:any)=>{
    navigation.navigate('Noticia', {excerpt: props.excerpt, title: props.title, content: props.content, image: props.image, sem: props.sem})
    scrollToTop? scrollToTop(): null
}

export default PostComponent;
