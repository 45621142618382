import axios from 'axios';

import { useEffect,useState } from 'react';
let baseURL = 'https://acamisa9.com.br/wp-json'

class WpApi {
    
    private setPosts
    private offset
    private postsPerRequest
    constructor(outSetPosts: any, postsPerRequest: number){
        this.setPosts = outSetPosts
        this.offset = 0
        this.postsPerRequest = postsPerRequest ? postsPerRequest : 5
    }
    api = axios.create({baseURL})

    private pullPosts = async () =>{
        
        let response = await this.api.get('/wp/v2/camisas/?_embed&tag&per_page='+ this.postsPerRequest +'&offset=' + this.offset).then(({data})=>data)
        

        // Extract the posts and their featured images
       return response.map((post: { _embedded?: any; id?: any; title?: any; content?: any; date?: any; slug?: any; excerpt?: any;}) => {
            const {id, title, content, date, slug, excerpt} = post;
            const image = post._embedded?.['wp:featuredmedia']?.[0]?.source_url || null;
            const tags = post._embedded?.['wp:term']?.[0]?.map((tag: { slug: string }) => tag.slug) || [];
            return {id, title, content, date, slug, image, excerpt, tags};
        });    
    }

    pullData = async () =>{
        let response = await this.api.get('/wp/v2/camisas/?_embed&tag').then(({data})=>data)
        

        // Extract the posts and their featured images
       return response.map((post: any) => {
            console.log(post);
        });    
    }



    private pullSpecificPosts =async (id:string) => {
        let response = await this.api.get('/wp/v2/camisas/?_embed&tags=' + id).then(({data})=>data)
        const sem = id
        return response.map((post: { _embedded?: any; id?: any; title?: any; content?: any; date?: any; slug?: any; excerpt?: any;}) => {
            const {id, title, content, date, slug, excerpt} = post;
            const image = post._embedded?.['wp:featuredmedia']?.[0]?.source_url || null;
            const tags = post._embedded?.['wp:term']?.[0]?.map((tag: { slug: string }) => tag.slug) || [];
            return ({id, title, content, date, slug, image, excerpt, tags, sem});
        });
    }

    getSpecificPosts = async (id:string) =>{
        this.setPosts(await this.pullSpecificPosts(id))
        return
    }

    searchTags =async () => {
        
        let response = await this.api.get('/wp/v2/tags/').then(({data})=>console.log(data))
        

    }

    getLatestPosts = async () =>{
        this.setPosts(await this.pullPosts())
        return
    }

    private setOffset = (input:number)=>{
        this.offset = input
    }

    public getOffset = ()=>{
        return this.offset
    }

    public aditionalPosts = async (offset: number, id?: string | undefined ) =>{
        this.setOffset(offset)
        if(id){
            return await this.pullSpecificPosts(id)
        }
        return (await this.pullPosts())
    }
}

export default WpApi;